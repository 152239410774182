<template>
  <div>
    <!-- <this-header /> -->

    <div class="anan-set-nav-conttainer mt-150 col-lg-8 col-md-12 col-sm-12">
      <router-link :to="{ name: 'new-pages' }">
        <b-button class="btn btn-gradient-primary mb-1 mr-50 mr-50 d-md-none">
          <i class="fas fa-arrow-left mr-25" /> {{ $t("key-126") }}
        </b-button>
      </router-link>
      <b-alert variant="danger" show>
        <b-col md="12" >
          <div class=" p-2 mt-2 bg-blue">
            <div class="col-md-3"></div>
              <img src="/details-before-ordering.png" alt="" width="100%" height="100%" style="border-radius: 10px;
              box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);"/>
            <div class="col-md-3"></div>
          </div>
        </b-col>
      </b-alert>
      <div class="BjIo5w">
        <div class="mcsiKT">
          <b-form-checkbox
            v-model="selectAllShops"
            @input="AllProduct('AllShops')"
          />
        </div>
        <div class="yl931K">{{ $t("key-105") }} ({{ Total.Order }})</div>
        <div class="pZMZa7">
          {{ $t("pricePerItem") }}
        </div>
        <div class="lKFOxX">
          {{ $t("quantity") }}
        </div>
        <div class="_5f317z">
          {{ $t("totalPrice") }}
        </div>
        <div class="+4E7yJ">
          {{ $t("action") }}
        </div>
      </div>
      <!-- 5 -->
      <div v-if="items.shops">
        <div
          v-for="(shop, inshop) in items.shops"
          :key="inshop"
          class="_48e0yS"
        >
          <div class="SFF7z2">
            <div class="xP1eaK">
              <div class="_5sTIHk">
                <b-form-checkbox
                  v-model="selectAllProductArr[inshop]"
                  @input="
                    AllProduct(
                      'AllProducts',
                      selectAllProductArr[inshop],
                      shop.products
                    )
                  "
                />
              </div>
              <span class="wJCpl6" style="margin-left: 1px"
                >{{ shop.storename }}
              </span>
              <div class="w-100 small">
                <b-form-checkbox
                  v-model="packbox"
                  :checked="true"
                  :unchecked-value="false"
                  name="check-button"
                  switch
                  inline
                  @change="ChangeHeadShop(shop)"
                >
                  {{ $t("woodPacking") }}
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="checkproduct"
                  :checked="true"
                  :unchecked-value="false"
                  name="check-button"
                  switch
                  inline
                  @change="ChangeHeadShop(shop)"
                >
                  {{ $t("checkOrder") }}
                </b-form-checkbox>
                {{
                  shop.packbox && shop.checkproduct
                    ? "(QC)(打木架)"
                    : shop.packbox
                    ? "(打木架)"
                    : shop.checkproduct
                    ? "(QC)"
                    : ""
                }}
              </div>
            </div>
          </div>

          <div
            v-for="(item, index) in shop.products"
            :key="index"
            class="Eb+POp"
          >
            <div class="VPZ9zs">
              <div class="zoXdNN">
                <div class="lgcEHJ">
                  <div class="_5sTIHk">
                    <b-form-checkbox
                      v-model="
                        items.shops[inshop].products[index].SelectProduct
                      "
                      @input="AllProduct('Product')"
                    />
                  </div>
                </div>
                <div class="eUrDQm">
                  <div class="LAQKxn">
                    <a title="text" @click="GoProduct(item, shop.provider)">
                      <div
                        class="WanNdG"
                        :style="{
                          backgroundImage:
                            'url(' +
                            (item.color_img
                              ? item.color_img
                              : item.size_img
                              ? item.size_img
                              : item.picture) +
                            ')',
                        }"
                      />
                    </a>
                    <div class="TyNN8t d-none d-md-block">
                      <a
                        class="JB57cn"
                        title="text"
                        @click="GoProduct(item, shop.provider)"
                        >{{ item.name }}</a
                      >
                      <div
                        class="eQNnTs"
                        style="
                          background-image: url('https://down-th.img.susercontent.com/file/th-50009109-741ebbf31c114b47d49fc38d39d61d21');
                        "
                      />
                      <div class="QRuJU-">
                        <span />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="o7pJBk d-none d-md-block">
                  <div class="MBOFLv">
                    <div class="S-Rdfh" role="button" tabindex="0">
                      <div v-if="item.color_title" class="dcPz7Y">
                        {{ $t("color") }}: {{ item.color_title }}
                      </div>
                      <div v-if="item.size_title" class="dcPz7Y">
                        {{ $t("size") }}: {{ item.size_title }}
                      </div>
                      <div v-if="item.details" class="dcPz7Y">
                        {{ $t("details") }}: {{ item.details }}
                      </div>
                      <p v-if="item.fileimg" style="font-weight: bold">
                        ไฟล์แนบ:

                        <img
                          :src="`https://api.anan-cargo.com/api/Order/getimg/${item.fileimg}`"
                          alt="Preview"
                          width="50"
                          class="text-center"
                          @click="
                            showPicture(
                              `https://api.anan-cargo.com/api/Order/getimg/${item.fileimg}`
                            )
                          "
                        />

                        <!-- <img
                            :src="`http://127.0.0.1:4444/api/Order/getimg/${item.fileimg}`"
                            alt="Preview"
                            width="50"
                            class="text-center"
                            @click="showPicture(`http://127.0.0.1:4444/api/Order/getimg/${item.fileimg}`)"
                          > -->
                      </p>
                      <p v-if="item.remark" style="font-weight: bold">
                        หมายเหตุ: {{ item.remark }}
                      </p>
                    </div>
                    <div />
                  </div>
                </div>

                <div class="G7E4B7 d-none d-md-block">
                  <div>
                    <span class="M-AAFK">¥ {{ Commas(item.price) }}</span>
                    |
                    <span class="M-AAFK">
                      ฿ {{ Commas(item.price * Rate) }}</span
                    >
                  </div>
                </div>
                <div class="MRh9G6">
                  <div>
                    <span>¥ {{ Commas(item.price * item.quantity) }}</span>
                    <br />
                    <span
                      >฿ {{ Commas(item.price * Rate * item.quantity) }}</span
                    >
                  </div>
                  <div class="mXmGu+ anan-input-quantity">
                    <!-- <b-form-spinbutton
                      id="sb-inline"
                      v-model="item.quantity"
                      inline
                      max="999999"
                      min="1"
                      @change="ChangeVal(item, shop)"
                    /> -->
                    <!-- {{ shop }} -->

                    <vue-numeric-input
                      v-model="item.quantity"
                      :min="1"
                      :max="999999"
                      :step="1"
                      @input="ChangeVal(item, shop)"
                    />
                  </div>
                </div>
                <!-- <div class="ofQLuG">
                  <span>¥ {{ Commas(item.price * item.quantity) }}</span>
                  <span>฿ {{ Commas(item.price * Rate * item.quantity) }}</span>
                </div> -->
                <div class="mhcjog _0p-F-m">
                  <button class="fX1Y2g" @click="DeleteProduct(item, shop)">
                    {{ $t("delete") }}
                  </button>
                </div>
              </div>
            </div>
            <div class="ur8hET" />
          </div>
        </div>
      </div>

      <div class="rnocow uEhFYV">
        <!-- <div class="exGqmz WjgNv5">
          <div class="C9vN6U">
            โค้ดส่วนลดของคุณ
          </div>
          <div class="GdUwdD" />
          <span class="ORcGEd">เลือกโค้ดส่วนลด</span>
        </div> -->
        <!-- <div class="h-Ivjs _1Xi-wS" />
        <div class="nPjHJw ceZa-G">
          <label class="stardust-checkbox stardust-checkbox--disabled"> -->
        <!-- <input
              class="stardust-checkbox__input"
              type="checkbox"
            >
            <div class="stardust-checkbox__box" /> -->
        <!-- </label>
        </div> -->
        <!-- <div class="nPjHJw K98a1N _1dLWkp r71pqT"> -->
        <!-- <div class="korYGr">
            Coins
          </div> -->
        <!-- <div class="WpXi0X">
            คุณยังไม่ได้เลือกสินค้า
          </div>
        </div>
        <div class="nPjHJw _4kUN8t _9d15-k">
          -฿0
        </div> -->
        <div class="h-Ivjs ivEpTY" />
        <div class="s1Gxkq c2pfrq">
          <div class="wqjloc">
            <label class="stardust-checkbox">
              <b-form-checkbox
                v-model="selectAllShops"
                @input="AllProduct('AllShops')"
              />
              <div class="stardust-checkbox__box" />
            </label>
          </div>
          <button class="iGlIrs clear-btn-style">
            {{ $t("key-105") }} ({{ Total.Order }})</button
          ><button class="clear-btn-style ukPYq9">
            <!-- {{ $t('delete') }} -->
          </button>
          <div class="" />
          <!-- <button class="clear-btn-style KbDVuv">
            {{ $t('moveToFavorites') }}
          </button> -->
          <div class="UlxAss" />
          <div class="UQv8V6">
            <div class="fyYBP1">
              <div class="aiyQAr">
                <div class="A-CcKC">
                  {{ $t("total") }} ({{ Total.Order }} {{ $t("product") }}):
                </div>
                <div class="WC0us+">¥ {{ Commas(Total.Price) }}</div>
              </div>
            </div>
            <div class="onR5FG" />
          </div>
          <button
            class="anan-button-solid anan-button-solid--primary"
            @click="Submit"
          >
            <span class="TTXpRG">{{ $t("orderProduct") }}</span>
          </button>
        </div>
      </div>
    </div>

    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
    />
  </div>
</template>

<script>
import { BFormCheckbox, BFormSpinbutton, BAlert } from "bootstrap-vue";
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import VueNumericInput from "vue-numeric-input";
import VueImageLightboxCarousel from "vue-image-lightbox-carousel";

export default {
  components: {
    BFormCheckbox,
    BFormSpinbutton,
    // ThisHeader,
    VueNumericInput,
    VueImageLightboxCarousel,
    BAlert,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      selectRow: [],
      selectAll: false,
      selectAllShops: false,
      selectAllProductArr: [],
      // selectAllProduct: false,
      packBox: false,
      checkProduct: false,
      value: 20,
      items: [],
      sum: [],
      Rate: 0,
      product: [
        {
          id: 1,
          name: "[2ชิ้น2บาท]MW007กระดาษทิชชู่หนา 4 ชั้น 300 แผ่น ทิชชู่แบบดึงเหนียว แข็งแรง แบบพกพา กระดาษเช็ดมือ(แพ็ค1ชิ้น)",
          price: 20,
          qty: 1,
        },
        {
          id: 2,
          name: "test2",
          price: 20,
          qty: 1,
        },
        {
          id: 3,
          name: "test3",
          price: 20,
          qty: 1,
        },
        {
          id: 4,
          name: "test4",
          price: 20,
          qty: 1,
        },
        {
          id: 5,
          name: "test5",
          price: 20,
          qty: 1,
        },
        {
          id: 6,
          name: "test6",
          price: 20,
          qty: 1,
        },
      ],
      Total: {
        Order: 0,
        Price: 0,
      },
      packbox: false,
      checkproduct: false,
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.GetMycart();
    this.GetRate();
  },
  methods: {
    showPicture(picture) {
      this.showLightbox = true;
      const img = { path: picture };
      this.images.push(img);
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    closeBox() {
      this.showLightbox = false;
      this.images = [];
    },
    GetMycart() {
      this.$http
        .get("/cart/mycart")
        .then((response) => {
          this.items = response.data;
          if (this.items.shops.length > 0) {
            // eslint-disable-next-line
            this.items.shops.forEach((element) => {
              // eslint-disable-next-line no-param-reassign
              element.SelectShop = false;
              this.selectAllProductArr.push(false);
              this.packbox = element.packbox;
              this.checkproduct = element.checkproduct;
              element.products.forEach((product) => {
                // eslint-disable-next-line no-param-reassign
                product.SelectProduct = false;
              });
            });
          }
        })
        .catch((error) => console.log(error));
    },
    AllProduct(SelectType, SelectShop, Products) {
      if (SelectType) {
        if (SelectType === "AllShops") {
          if (this.selectAllShops) {
            this.items.shops.forEach((element, index) => {
              // eslint-disable-next-line no-param-reassign
              element.SelectShop = true;
              this.selectAllProductArr[index] = true;
              element.products.forEach((product) => {
                // eslint-disable-next-line no-param-reassign
                product.SelectProduct = true;
              });
            });
          } else {
            this.items.shops.forEach((element, index) => {
              // eslint-disable-next-line no-param-reassign
              element.SelectShop = false;
              this.selectAllProductArr[index] = false;
              element.products.forEach((product) => {
                // eslint-disable-next-line no-param-reassign
                product.SelectProduct = false;
              });
            });
          }
        } else if (SelectType === "AllProducts") {
          if (SelectShop) {
            Products.forEach((product) => {
              // eslint-disable-next-line no-param-reassign
              product.SelectProduct = true;
            });
          } else {
            Products.forEach((product) => {
              // eslint-disable-next-line no-param-reassign
              product.SelectProduct = false;
            });
          }
        }
        this.CalTotal();
      }
    },
    CalTotal() {
      this.Total.Order = 0;
      this.Total.Price = 0;
      if (this.items && this.items.shops.length > 0) {
        this.items.shops.forEach((Items) => {
          Items.products.forEach((product) => {
            if (product.SelectProduct) {
              this.Total.Order += 1;
              this.Total.Price +=
                Number(product.price) * Number(product.quantity);
            }
          });
        });
      }
    },
    async ChangeHeadShop(Shop) {
      this.$swal({
        title: "ยืนยันการทำรายการหรือไม่ ?",
        // text: `หรือไม่ ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ใช่",
        cancelButtonText: "ไม่ใช่",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const obj = {
            // eslint-disable-next-line no-underscore-dangle
            shops_id: Shop._id,
            packbox: this.packbox,
            checkproduct: this.checkproduct,
          };
          this.$http
            .post("/cart/CheckAndPack", obj)
            .then((response) => {
              if (response.data) {
                this.GetMycart();
              }
            })
            .catch((error) => {
              console.log(error);
              this.Alert(false, "เกิดข้อผิดพลาด", "ไม่สามารถสั่งสินค้าได้");
            });
        } else {
          if (!result.isConfirmed) {
            this.packbox = Shop.packbox;
            this.checkproduct = Shop.checkproduct;
          }
          this.$swal("ยกเลิก", "ยกเลิกคำสั่ง !", "error");
        }
      });
      // try {
      //   const obj = {
      //     // eslint-disable-next-line no-underscore-dangle
      //     shops_id: Shop.shops_id,
      //     packbox: Shop.packbox,
      //     checkproduct: Shop.checkproduct,
      //   }
      //   await this.$http.post('/cart/CheckAndPack', obj)
      //   localStorage.setItem('Order', JSON.stringify(this.order))
      // } catch (error) {
      //   console.log(error)
      // }
    },
    // eslint-disable-next-line no-unused-vars
    async ChangeVal(Product, Shop) {
      try {
        const obj = {
          // eslint-disable-next-line no-underscore-dangle
          shops_id: Shop._id,
          // eslint-disable-next-line no-underscore-dangle
          products_id: Product._id,
          quantity: Product.quantity,
        };
        const { data: res } = await this.$http.post(
          "/cart/ChangeQuantity",
          obj
        );

        if (res.status) {
          // eslint-disable-next-line no-param-reassign
          Product.price = res.price;
          // console.log(Product.price)
        }
      } catch (error) {
        console.log(error);
      }
    },
    Submit() {
      const obj = [];
      const Order = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const Items of this.items.shops) {
        const FilterProduct = Items.products.filter(
          (product) => product.SelectProduct
        );
        if (FilterProduct && FilterProduct.length > 0) {
          const OrderObj = {
            // eslint-disable-next-line no-underscore-dangle
            shops_id: Items._id,
            storename: Items.storename,
            checkproduct: Items.checkproduct,
            packbox: Items.packbox,
            provider: Items.provider,
            products: FilterProduct,
          };
          Order.push(OrderObj);
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const product of Items.products) {
          if (product.SelectProduct) {
            obj.push({
              // eslint-disable-next-line no-underscore-dangle
              shops_id: Items._id,
              // eslint-disable-next-line no-underscore-dangle
              products_id: product._id,
            });
          }
        }
      }
      if (obj.length === 0) {
        this.SwalError("กรุณาเลือกสินค้า !!");
      } else {
        const OrderStr = JSON.stringify(Order);
        const OrderIdStr = JSON.stringify(obj);
        localStorage.setItem("Order", OrderStr);
        localStorage.setItem("OrderId", OrderIdStr);
        this.$router.push({ name: "checkout" });
      }
    },
    DeleteProduct(data, shop) {
      this.$swal({
        title: "ยืนยันที่จะลบสินค้า ?",
        text: `ชื่อ ${data.name ? data.name : "-"} หรือไม่ ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const obj = {
            // eslint-disable-next-line no-underscore-dangle
            shops_id: shop._id,
            // eslint-disable-next-line no-underscore-dangle
            products_id: data._id,
          };
          this.$http
            .post("/cart/deleteIncart", obj)
            // eslint-disable-next-line no-unused-vars
            .then((response) => {
              this.GetMycart();
              this.Success("ลบสินค้าสำเร็จ");
            })
            .catch((error) => {
              this.SwalError(error.response.data.message);
            });
        } else {
          this.$swal("ยกเลิก", "ยกเลิกคำสั่ง !", "error");
        }
      });
    },
    GoProduct(item, provider) {
      if (provider === "anancargo") {
        this.$router.push({ name: "product", params: { id: item.id } });
      } else {
        this.GetProductByUrl(item.url);
      }
    },
    async GetRate() {
      try {
        const { data: res } = await this.$http.get("/product/rate");
        if (res.status) {
          this.Rate = res.data.cSelling;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async GetProductByUrl(URLData) {
      const obj = {
        provider: "",
        id: "",
      };
      const url = new URL(URLData);
      const { host, pathname, search } = url;
      if (host && host.length > 0) {
        const index1688 = host.indexOf("1688");
        const indextaobao = host.indexOf("taobao");
        const indextmall = host.indexOf("tmall");
        const indexweidian = host.indexOf("weidian");
        const indexalibaba = host.indexOf("alibaba");
        if (index1688 > -1) {
          obj.provider = "1688";
          if (host === "mindx.1688.com") {
            obj.id = url.searchParams.get("offerId");
          } else {
            const IndexCheck = pathname.indexOf("/offer/");
            if (IndexCheck > -1) {
              const of = new RegExp("/offer/", "g");
              const ht = new RegExp(".html", "g");
              obj.id = pathname.replace(of, "");
              obj.id = obj.id.replace(ht, "");
            }
          }
        } else if (indextaobao > -1) {
          obj.provider = "taobao";
          const IndexCheck = search.indexOf("itemIds");
          if (IndexCheck > -1) {
            obj.id = url.searchParams.get("itemIds");
          } else {
            obj.id = url.searchParams.get("id");
          }
        } else if (indextmall > -1) {
          obj.provider = "tmall";
          obj.id = url.searchParams.get("id");
        } else if (indexweidian > -1) {
          obj.provider = "weidian";
          const IndexCheck = search.indexOf("itemID");
          if (IndexCheck > -1) {
            obj.id = url.searchParams.get("itemID");
          } else {
            obj.id = url.searchParams.get("id");
          }
        } else if (indexalibaba > -1) {
          obj.provider = "alibaba";
          const IndexCheck = pathname.indexOf("/product-detail/");
          if (IndexCheck > -1) {
            const of = new RegExp("/product-detail/", "g");
            const ht = new RegExp(".html", "g");
            obj.id = pathname.replace(of, "");
            obj.id = obj.id.replace(ht, "");
          }
        }
        if (obj.provider && obj.id) {
          this.$router.push({
            name: `product2-${obj.provider}`,
            params: { id: obj.id },
          });
        } else {
          this.Alert(
            false,
            "ไม่พบข้อมูลสินค้า",
            "กรุณากรอก URL ใหม่ให้ถูกต้อง"
          );
        }
      }
    },
    Alert(status, title, mes) {
      this.$swal({
        icon: `${status ? "success" : "error"}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    Success(mes) {
      this.$swal({
        icon: "success",
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    SwalError(mes) {
      this.$swal({
        icon: "error",
        title: `<h3 style="color: #141414">${this.$t("key-298")}</h3>`,
        text: mes,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .small {
    transform: scale(0.9);
    display: flex !important;
    flex-wrap: wrap !important;
    margin: -10px !important;
    gap: 5px !important;
  }

  .mXmGu\+ {
    transform: scale(0.7);
  }

  .WanNdG {
    width: 80px !important;
  }

  .wJCpl6 {
    font-size: 8px !important;
  }

  .s1Gxkq {
    font-size: 10px;
  }

  .A-CcKC {
    font-size: 10px;
  }

  .UQv8V6 div {
    font-size: 12px !important;
  }

  .BjIo5w div {
    font-size: 9px;
  }
  .BjIo6w div {
    font-size: 9px;
  }

  .MRh9G6 {
    min-width: 80px;
    font-size: 11px;
    font-weight: 700;
  }

  .G7E4B7 {
    min-width: 80px;
    font-size: 11px;
    font-weight: 700;
  }

  .S-Rdfh {
    min-width: 80px;
    font-size: 11px;
    font-weight: 700;
  }

  .mhcjog {
    min-width: 70px !important;
  }

  .lgcEHJ {
    min-width: 20px !important;
  }
}
</style>
